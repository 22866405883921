import React from "react"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"

const StyledArticle = styled.article`
  h3 {
    ${fonts.headingMedium}
  }
  h4 {
    ${fonts.headingSmall}
  }
  h3,
  h4 {
    + p {
      margin-top: 20px;
    }
  }
  p {
    ${fonts.paragraph};
    strong {
      font-weight: 700;
    }
    + p,
    + ul,
    + ol {
      margin-top: 1em;
    }
  }
  ol {
    list-style-type: upper-roman;
  }
  ul {
    list-style-type: disc;
  }

  ol,
  ul {
    list-style-position: inside;
    &.strong {
      font-weight: 700;
    }
    li {
      ${fonts.paragraph};
    }
    + p {
      margin-top: 1em;
    }
  }
`

const FormattedArticle = ({ article }) => (
  <StyledArticle dangerouslySetInnerHTML={{ __html: article }} />
)

export default FormattedArticle
