import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { rgba } from "polished";
import { fonts } from "../../utils/fonts";
import { Link } from "gatsby";

const StyledWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 310px;
`;

const StyledText = styled.p`
  ${fonts.paragraph};
  font-size: 18px;
  text-align: center;
`;

const StyledImageWrapper = styled.figure`
  height: 182px;
  width: 182px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 40px;
  background-color: ${({ backgroundColor }) => rgba(backgroundColor, 0.1)};
`;

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled(SVG)`
  * {
    fill: ${({ color }) => color}
`;

const FeatureCard = ({ svg, svgAlt, text, backgroundColor, link }) => {
  return (
    <StyledWrapper as={link && Link} to={link}>
      <StyledImageWrapper backgroundColor={backgroundColor}>
        {svg && <SvgWrapper>
          <StyledImage src={svg} alt={svgAlt} color={backgroundColor} />
        </SvgWrapper>}
      </StyledImageWrapper>
      <StyledText>{text}</StyledText>
    </StyledWrapper>
  );
};

export default FeatureCard;
