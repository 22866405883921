import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"
import { color } from "../../utils/colors"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import FormattedArticle from "../../components/FormattedArticle/FormattedArticle"
import { Link } from "gatsby"

const StyledRowWrapper = styled.div`
  display: flex;
  border-bottom: solid 1px
    ${({ noBorder }) => (noBorder ? "transparent" : color.separatorGrey)};
  padding-bottom: 80px;
  padding-top: 80px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 40px;
  }
`

const StyledTitleMedium = styled.h3`
  ${fonts.headingLarge};
  width: calc(100% / 3);
  padding-right: 40px;
  border-right: solid 1px ${color.separatorGrey};
  color: ${({ color }) => color};
  padding-bottom: 150px;
  @media screen and (max-width: 1000px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1000px) {
    padding-right: 30px;
    width: 100%;
    padding-bottom: 20px;
    border-right: none;
  }
`

const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  padding-left: 40px;
  padding-bottom: 150px;

  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-bottom: 80px;
  }
  &:not(:last-child) {
    padding-right: 40px;
    border-right: solid 1px ${color.separatorGrey};
    @media screen and (max-width: 1100px) {
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    padding-left: 0;
    border-right: none !important;
    padding-bottom: 20px;
  }
`

const StyledLink = styled(Link)`
  font-size: 18px;
  line-height: 33px;
  font-weight: 700;
  margin-top: 40px;
  @media screen and (max-width: 1000px) {
    margin-top: 24px;
  }
`

const ThreeColSection = ({
  id,
  heading,
  article1,
  article2,
  link1,
  link2,
  noBorder,
  headingColor,
}) => (
  <LayoutWrapper id={id}>
    <StyledRowWrapper noBorder={noBorder}>
      <StyledTitleMedium color={headingColor}>{heading}</StyledTitleMedium>
      <StyledColumnWrapper>
        <FormattedArticle article={article1} />
        {link1 && <StyledLink to={link1}>Czytaj więcej</StyledLink>}
      </StyledColumnWrapper>
      <StyledColumnWrapper>
        <FormattedArticle article={article2} />
        {link2 && <StyledLink to={link2}>Czytaj więcej</StyledLink>}
      </StyledColumnWrapper>
    </StyledRowWrapper>
  </LayoutWrapper>
)

ThreeColSection.propTypes = {
  heading: PropTypes.string.isRequired,
  article1: PropTypes.string,
  article2: PropTypes.string,
}

export default ThreeColSection
