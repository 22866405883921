import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import { color } from "../../utils/colors"
import Button from "../../components/Button/Button"
import FormattedArticle from "../../components/FormattedArticle/FormattedArticle"

const StyledWrapper = styled(LayoutWrapper)``

const StyledTitleLarge = styled.h2`
  ${fonts.headingLarge};
  width: calc(100% / ${({ equalSections }) => (equalSections ? 2 : 3)});
  flex-shrink: 0;
  padding-right: 40px;
  border-right: solid 1px ${color.separatorGrey};
  color: ${({ color }) => color};
  padding-bottom: 150px;
  @media screen and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1000px) {
    border-right: none;
    padding-bottom: 20px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
`

const StyledButton = styled(Button)`
  margin-top: 80px;
  align-self: flex-start;
  @media screen and (max-width: 1000px) {
    margin-top: 40px;
  }
`

const StyledColumnWrapper = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  @media screen and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 0;
    padding-bottom: 0;
  }
`

const StyledRowWrapper = styled.div`
  display: flex;
  border-bottom: solid 1px
    ${({ noBorder }) => (noBorder ? "transparent" : color.separatorGrey)};

  padding-top: 100px;
  padding-bottom: 80px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 40px;
  }
`

const TwoColumnSection = ({
  id,
  heading,
  article,
  buttonText,
  buttonLink,
  equalSections,
  noBorder,
  headingColor,
}) => (
  <StyledWrapper id={id}>
    <StyledRowWrapper noBorder={noBorder}>
      <StyledTitleLarge equalSections={equalSections} color={headingColor}>
        {heading}
      </StyledTitleLarge>
      <StyledColumnWrapper>
        <FormattedArticle article={article} />
        {buttonLink && (
          <StyledButton to={buttonLink}>{buttonText}</StyledButton>
        )}
      </StyledColumnWrapper>
    </StyledRowWrapper>
  </StyledWrapper>
)

TwoColumnSection.propTypes = {
  heading: PropTypes.string.isRequired,
  article: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  equalSections: PropTypes.bool,
}

export default TwoColumnSection
