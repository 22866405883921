import { Link } from "gatsby"
import { rgba } from "polished"
import styled from "styled-components"
import { color } from "../../utils/colors"
import { fonts } from "../../utils/fonts"

const Button = styled(Link)`
  ${fonts.button};
  height: 80px;
  padding: 0 88px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 2px ${color.black};
  border-radius: 5px;
  background-color: ${color.white};
  text-align: center;
  transition: all 0.2s;
  @media screen and (max-width: 1200px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 1100px) {
    height: 60px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
  @media (hover: hover), not all and (-moz-touch-enabled) {
    :hover {
      background: ${rgba(color.white, 0.5)};
    }
  }
`

export default Button
