import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import { color } from "../../utils/colors"
import FeatureCard from "../../components/FeatureCard/FeatureCard"

const StyledWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 100px;
  border-bottom: solid 1px
    ${({ noBorder }) => (noBorder ? "transaprent" : color.separatorGrey)};
  @media screen and (max-width: 1000px) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
`

const StyledTitle = styled.h3`
  ${fonts.headingMedium};
  text-align: center;
`

const StyledSubtitle = styled.p`
  ${fonts.headingExtraSmall};
  text-align: center;
  margin-top: 20px;
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -90px;
  :not(:first-child) {
    margin-top: 80px;
    @media screen and (max-width: 1000px) {
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: -50px;
  }
`

const StyledItemWrapper = styled.div`
  width: calc(100% / ${({ colNumber }) => colNumber});
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: ${({ itemsSeparator }) => itemsSeparator && "150px"};
  :not(:last-child) {
    border-right: solid 1px
      ${({ itemsSeparator }) =>
        itemsSeparator ? color.separatorGrey : "transparent"};
  }
  @media screen and (max-width: 1100px) {
   padding-bottom: ${({ itemsSeparator }) => itemsSeparator && "80px"};
    width: calc(
      100% /
        ${({ colNumber, itemsNumber }) =>
          colNumber === 4 ? 2 : itemsNumber % 2 === 0 ? 2 : 3}
    );
  }
  @media screen and (max-width: 1000px) {
    width: calc(
      100% /
        ${({ colNumber, itemsNumber }) =>
          colNumber === 4 ? 2 : itemsNumber % 2 === 0 ? 2 : 1}
    );
    border-right: none !important;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 50px;
  }
`

const FeaturesList = ({
  title,
  subtitle,
  items,
  colNumber,
  noBorder,
  itemsSeparator,
}) => (
  <LayoutWrapper>
    <StyledWrapper noBorder={noBorder}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      <StyledRow noBorder>
        {items.map(item => {
          return (
            <StyledItemWrapper
              colNumber={colNumber}
              itemsSeparator={itemsSeparator}
              itemsNumber={items.length}
            >
              <FeatureCard {...item} />
            </StyledItemWrapper>
          )
        })}
      </StyledRow>
    </StyledWrapper>
  </LayoutWrapper>
)

FeaturesList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.array.isRequired,
  ItemTemplate: PropTypes.elementType,
  colNumber: PropTypes.number,
  noBorder: PropTypes.bool,
}

FeaturesList.defaultProps = {
  colNumber: 4,
}

export default FeaturesList
